<template>
    <input
            @input="onInput"
            @blur="onBlur"
            :class="{ 'is-invalid': checkValidity && !this.isValid }"
            v-model="displayValue"
    />
</template>

<script>
  import _ from 'lodash'

  export default {
    props: {
      value: {type: String},
      minLength: {type: Number, required: true},
      maxLength: {type: Number},
      alphabet: {type: String, default: '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'},
      upcaseOnly: {type: Boolean, default: true},
      invalidCodes: {type: Array, default: []},
    },
    data() {
      return {
        displayValue: this.value,
        checkValidity: !!this.value
      }
    },
    computed: {
      isValid() {
        return this.value &&
          (this.value.length >= this.minLength && this.value.length <= (this.maxLength || this.minLength)) &&
          !_.includes(this.invalidCodes, this.value)
      }
    },
    methods: {
      onInput(event) {
        const value = event.target.value;
        const newValue = this.formatValue(value);
        this.displayValue = newValue;
        this.$emit('input', newValue);
      },
      onBlur(event) {
        this.checkValidity = true;
      },
      formatValue(value) {
        if (!value) {
          return '';
        }
        value = value.toString();
        if (this.upcaseOnly) {
          value = value.toUpperCase();
        }
        return value.replace(new RegExp('[^' + this.alphabet + ']+', this.upcaseOnly ? 'gi' : 'g'), '')
      },
    },
    watch: {
      value(newValue) {
        this.$emit('valid', this.isValid)
      }
    }
  }
</script>
