<template>
    <input
            @input="onInput"
            @blur="onBlur"
            :class="{ 'is-invalid': checkValidity && !this.isValid }"
            v-model="displayValue"
    />
</template>

<script>
  export default {
    props: {
      value: {type: String},
      minLength: {type: Number, required: true},
      maxLength: {type: Number}
    },
    data() {
      return {
        displayValue: this.value,//.toString().replace(/^\+49/, '0'),
        checkValidity: !!this.value
      }
    },
    computed: {
      isValid() {
        return this.value && this.value.match(/^\+[^0]\d+$/) && this.value.length - 3  >= this.minLength; // - 3 because of the leading country prefix "+49"
      }
    },
    methods: {
      onInput(event) {
        const value = event.target.value;
        this.displayValue = this.formatDisplayValue(value);
        this.$emit('input', this.formatValue(value));
      },
      onBlur(event) {
        this.checkValidity = !!this.value;
      },
      formatDisplayValue(value) {
        if (!value) {
          return ''
        }
        value = value.toString()
        // ltrim
        value = value.replace(/^\s+/, '')
        // allow "+49" etc. but without spaces
        value = value.replace(/^\+\D+/, '+')
        const leadingPlus = !!value.match(/^\+/)
        // remove all unsupported characters
        value = value.replace(/[^\d\s\-\/]+/g, '')
        // replace whitespace with spaces, replace multiple non-digits with single space
        value = value.replace(/\s/g, ' ').replace(/([\s\-\/])\1+/g, "$1")
        if (leadingPlus) {
          value = "+" + value
        }

        return value;
      },
      formatValue(value) {
        if (!value) {
          return '';
        }
        value = this.formatDisplayValue(value)
        // save leading "+"
        const leadingPlus = !!value.match(/^\s*\+/)
        // remove all non-digits
        value = value.replace(/\D+/g, '')
        // restore leading "+"
        if (leadingPlus) {
          value = "+" + value
        }
        // replace leading "00" with "+", e.g 0049175 -> +49175, but not 00049175!
        value = value.replace(/^00([^0])/, "+$1")
        // replace leading "0" with "+49", e.g. 0175 -> +49175
        value = value.replace(/^0([^0])/, "+49$1")
        // add "+" to numbers starting with a digit that's not "0". e.g. 49175 -> +49175
        value = value.replace(/^([1-9])/, "+$1")

        return value;
      },
    },
    watch: {
      value(newValue) {
        this.$emit('valid', this.isValid)
      }
    }
  }
</script>
