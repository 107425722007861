/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

console.log('Loaded application.js')

import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from 'vue'
import Rollbar from 'vue-rollbar'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Registration from './registration'
import Calendar from './calendar'

Vue.use(Rollbar, {
  accessToken: __RAILS.rollbar_token,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: __RAILS.rollbar_env !== 'development',
  source_map_enabled: true,
  environment: __RAILS.rollbar_env,
  payload: {
    client: {
      javascript: {
        code_version: '1.0'
      }
    }
  }
})

axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('head meta[name=csrf-token]').getAttribute('content')
Vue.use(VueAxios, axios)

document.addEventListener('DOMContentLoaded', () => {
  console.log('Mounting applications...')

  Registration().$mount('#registration')
  Calendar().$mount('#calendar')

  console.log('Applications mounted')
})
