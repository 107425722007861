<template lang="pug">
    .col-3.col-md-2.Day(:class="{ inactive: !active, active: active }")
        .Day-image(@click="onClick")
            img(:src="imagePath" :alt="alt")
</template>

<script>
  export default {
    props: {
      id: {type: String, required: true},
      active: {type: Boolean, default: false},
      imagePath: {type: String, required: true},
      alt: {type: String, required: true},
      partnerPrizes: {type: Array, required: false},
      headline: {type: String, required: true},
      text: {type: String, required: false},
    },
    data() {
      return {}
    },
    computed: {},
    methods: {
      onClick(event) {
        console.log(event)
        if (this.active) {
          this.$emit('door-open', {
            id: this.id,
            active: this.active,
            imagePath: this.imagePath,
            alt: this.alt,
            partnerPrizes: this.partnerPrizes,
            headline: this.headline
          })
        } else {
          this.$emit('door-closed', {
            id: this.id,
            active: this.active,
            imagePath: this.imagePath,
            alt: this.alt,
            headline: this.headline,
            text: this.text
          })
        }
      },
    }
  }
</script>
