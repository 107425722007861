import Vue from 'vue'
import CalendarDoor from '../calendar-door'

export default function () {
  return new Vue({
    components: {CalendarDoor},

    data() {
      return {
        modal: null
      }
    },

    methods: {
      onDoorOpen(modalData) {
        console.log('onDoorOpen', arguments)
        this.modal = modalData
      },
      onDoorClosed(modalData) {
        console.log('onDoorClosed', arguments)
        this.modal = modalData
      },
      close() {
        this.modal = null
      }
    },

    computed: {
      showModal() {
        return !!this.modal
      }
    },

    watch: {}
  })
}
