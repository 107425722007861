<template>
    <input
            @input="onInput"
            @blur="onBlur"
            :class="{ 'is-invalid': checkValidity && !this.isValid }"
            v-model="displayValue"
    />
</template>

<script>
  export default {
    props: {
      value: {type: String},
      minLength: {type: Number, required: true},
      maxLength: {type: Number}
    },
    data() {
      return {
        displayValue: this.value,
        checkValidity: !!this.value
      }
    },
    computed: {
      isValid() {
        return !!(
          this.value && this.value.match(/^.+@([a-z0-9äöüß\-]+\.)+[a-z]{2,}$/i)
        )
      }
    },
    methods: {
      onInput(event) {
        const value = event.target.value;
        this.displayValue = this.formatDisplayValue(value);
        this.$emit('input', this.formatValue(value));
      },
      onBlur(event) {
        this.checkValidity = !!this.value;
      },
      formatDisplayValue(value) {
        if (!value) {
          return '';
        }
        value = value.replace(/^\s+/, '')
        return value;
      },
      formatValue(value) {
        if (!value) {
          return '';
        }
        value = value.trim();
        return value;
      },
    },
    watch: {
      value(newValue) {
        this.$emit('valid', this.isValid)
      }
    }
  }
</script>
