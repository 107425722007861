import Vue from 'vue'
import CodeInput from '../code-input'
import MobileInput from '../mobile-input'
import EmailInput from '../email-input'
import VueRecaptcha from 'vue-recaptcha'
import _ from 'lodash'

export default function () {

  const STATES = {
    INITIAL: 'INITIAL',
    REQUEST_RUNNING: 'REQUEST_RUNNING',
    REQUEST_FINISHED: 'REQUEST_FINISHED',
    REQUEST_ERROR: 'REQUEST_ERROR'
  };

  return new Vue({
    components: {CodeInput, MobileInput, EmailInput, VueRecaptcha},

    data() {
      return {
        code: null,
        codeValid: false,
        notify_via: null,
        email: null,
        emailValid: false,
        mobile: null,
        mobileValid: false,
        accept_terms_and_conditions: false,
        accept_data_privacy: false,

        state: STATES.INITIAL,
        result: null,

        invalidCodes: [],
      }
    },

    methods: {
      onSubmit() {
        if (!this.canSubmit) {
          return
        }
        this.state = STATES.REQUEST_RUNNING
        this.$refs.recaptcha.execute()
      },
      onCaptchaVerified(token) {
        this.state = STATES.REQUEST_RUNNING
        this.$refs.recaptcha.reset()

        let pData = {
          code: this.code,
          notify_via: this.notify_via,
          accepted_terms_and_conditions: this.accept_terms_and_conditions,
          accepted_data_privacy: this.accept_data_privacy,
          recaptcha: token,
        }
        if (this.notify_via === "email") {
          pData['email'] = this.email
        } else if (this.notify_via === "sms") {
          pData['mobile'] = this.mobile
        }
        this.$http.post('/registrieren', {participant: pData})
          .then(response => {
            console.log("response", response)
            this.state = STATES.REQUEST_FINISHED;
            this.result = response.data
          })
          .catch(error => {
            console.log("error", error)
            if (!error.response || error.response.status !== 'abort') {
              this.result = null;
              this.state = STATES.REQUEST_ERROR;
            }
          })
      },
      onCaptchaExpired(event) {
        this.$refs.recaptcha.reset()
      },
      onNewCode() {
        this.state = STATES.INITIAL
        this.code = this.result = this.codeValid = null
      },
      onValid(event) {
        this.codeValid = event
      }
    },

    computed: {
      canSubmit() {
        return this.codeValid &&
          ((this.notify_via === "sms" && this.mobileValid) || (this.notify_via === "email" && this.emailValid)) &&
          this.accept_terms_and_conditions &&
          this.accept_data_privacy &&
          this.state !== STATES.REQUEST_RUNNING
      },
      showSpinner() {
        return this.state === STATES.REQUEST_RUNNING
      },
      showResult() {
        return this.state === STATES.REQUEST_FINISHED
      },
      resultHeadline() {
        return this.result ? _.head(this.result.messages) : null
      },
      resultMessages() {
        return this.result ? _.tail(this.result.messages) : []
      },
    },

    watch: {}
  })
}
